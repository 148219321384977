import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid2 as Grid, Typography } from "@mui/material";

import { RootState, store } from "@store/store";
import { ButtonType, OptionApplicationMenu, PopupType } from "@interfaces/slices";
import {
  setOpinionModuleLegalAgentId,
  setOpinionModuleMajorityShareholder,
  setOpinionModuleValues,
  setOptionAppSelected,
  setPopupConfig,
  setVisibleButtons,
} from "@store/slices/componentsSlice";
import { Heimdall } from "@components/Heimdall";

export const Options = () => {
  const dispatch = useDispatch();

  const optionPrevSelected = useSelector((state: RootState) => state.components.menuApplication.optionSelected);
  const permissions = (state = store.getState()) => state.app.session.permissions;

  const [optionSelected, setOptionSelected] = useState<OptionApplicationMenu>(optionPrevSelected);

  const handleOnChangeOption = (option: OptionApplicationMenu) => {
    dispatch(
      setPopupConfig({
        type: PopupType.NONE,
        props: undefined,
        anchorEl: undefined,
      }),
    );

    setOptionSelected(option);

    let btns = Array<{ btn: ButtonType; disabled: boolean }>();
    switch (option) {
      case OptionApplicationMenu.PERFIL_CREDITICIO:
      case OptionApplicationMenu.INFO_GRAL:
        btns.push(
          // { btn: ButtonType.VALIDAR_AUTORIZACION, disabled: false },
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        dispatch(setVisibleButtons(btns));
        break;
      // case OptionApplicationMenu.PERFIL:
      //   btns.push(
      //     { btn: ButtonType.VALIDAR_AUTORIZACION, disabled: false },
      //     { btn: ButtonType.RECHAZAR, disabled: false },
      //     { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
      //   );
      //   break;
      case OptionApplicationMenu.VISITA_OCULAR:
        btns.push(
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        dispatch(setVisibleButtons(btns));
        break;
      // case OptionApplicationMenu.DICTAMEN:
      case OptionApplicationMenu.DICTAMEN_LEGAL:
      case OptionApplicationMenu.CONTRACTUALES:
        btns.push(
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        dispatch(setVisibleButtons(btns));
        break;
      case OptionApplicationMenu.EXPEDIENTE:
        btns.push(
          { btn: ButtonType.VALIDAR_EXPEDIENTE, disabled: false },
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        dispatch(setVisibleButtons(btns));
        break;
      case OptionApplicationMenu.RESUMEN_OPERACION:
        btns.push(
          { btn: ButtonType.LIBERAR_DISPERSION, disabled: false },
          { btn: ButtonType.CONVERTIR_CLIENTE, disabled: false },
        );
        dispatch(setVisibleButtons(btns));
        break;
    }
    dispatch(setOptionAppSelected(option));

    if (option !== OptionApplicationMenu.DICTAMEN_LEGAL) {
      dispatch(
        setOpinionModuleValues({
          publicDeedNumber: "",
          publicDeedDate: "",
          notaryOfficeNumber: "",
          notaryName: "",
          municipality: "",
          federative: "",
          sufficientPowers: null,
          limitationContractCredit: null,
          commissionerName: "",
          commissionerLastName: "",
          commissionerLastName2: "",
          majorityShareholderValidate: null,
          legalAgentName: "",
          legalAgentLastName: "",
          legalAgentLastName2: "",
          legalAgentIsShareholder: null,
        }),
      );
      dispatch(setOpinionModuleLegalAgentId("0-0-0-0-0"));
      dispatch(setOpinionModuleMajorityShareholder("0-0-0-0-0"));
    }
  };

  useEffect(() => {
    let shouldBreak = false;
    [
      "MIGR",
      "SBPCG",
      "SBDASH",
      "SBFINA",
      "SBFLRE",
      "SBEXEM",
      "SBEXAV",
      "SBDODI",
      "SBMODI",
      "SBEXEM",
      "SBEXAV",
      "SBDODI",
      "SBMODI",
      "MVCR",
      "SBPLD",
      "SBLEG",
      "SBVISI",
      "SBAVIS",
      "MCCO",
      "MCON",
      "MRCR",
    ].forEach((t) => {
      if (shouldBreak) return;

      if (permissions(store.getState()).find((p) => p.module.code === t)) {
        shouldBreak = true;
        switch (t) {
          case "MIGR":
            handleOnChangeOption(OptionApplicationMenu.INFO_GRAL);
            break;
          case "SBPCG":
          case "SBDASH":
          case "SBFINA":
          case "SBFLRE":
            handleOnChangeOption(OptionApplicationMenu.PERFIL_CREDITICIO);
            break;
          case "MDIC":
            handleOnChangeOption(OptionApplicationMenu.DICTAMEN_LEGAL);
            break;
          case "MEXE":
            handleOnChangeOption(OptionApplicationMenu.EXPEDIENTE);
            break;
          case "MVCR":
            handleOnChangeOption(OptionApplicationMenu.VALIDACION_CREDITICA);
            break;
          case "SBPLD":
          case "SBLEG":
            handleOnChangeOption(OptionApplicationMenu.LEGAL_PLD);
            break;
          case "SBVISI":
          case "SBAVIS":
            handleOnChangeOption(OptionApplicationMenu.VISITA_OCULAR);
            break;
          case "MCCO":
            handleOnChangeOption(OptionApplicationMenu.REVISION_CONTROL);
            break;
          case "MCON":
            handleOnChangeOption(OptionApplicationMenu.CONTRACTUALES);
            break;
          case "MRCR":
            handleOnChangeOption(OptionApplicationMenu.RESUMEN_OPERACION);
            break;
        }
      }
    });
  }, []);

  useEffect(() => {
    let shouldBreak = false;
    ["MIGR", "MPCR", "MDIC", "MEXE", "MVCR", "MLPLD", "MVOC", "MRCO", "MCONT", "MRCL"].forEach((t) => {
      if (shouldBreak) return;

      if (permissions(store.getState()).find((p) => p.module.code === t)) {
        shouldBreak = true;
        switch (t) {
          case "MIGR":
            handleOnChangeOption(OptionApplicationMenu.INFO_GRAL);
            break;
          case "MPCR":
            handleOnChangeOption(OptionApplicationMenu.PERFIL_CREDITICIO);
            break;
          case "MDIC":
            handleOnChangeOption(OptionApplicationMenu.DICTAMEN_LEGAL);
            break;
          case "MEXE":
            handleOnChangeOption(OptionApplicationMenu.EXPEDIENTE);
            break;
          case "MVCR":
            handleOnChangeOption(OptionApplicationMenu.VALIDACION_CREDITICA);
            break;
          case "MLPLD":
            handleOnChangeOption(OptionApplicationMenu.LEGAL_PLD);
            break;
          case "MVOC":
            handleOnChangeOption(OptionApplicationMenu.VISITA_OCULAR);
            break;
          case "MRCO":
            handleOnChangeOption(OptionApplicationMenu.REVISION_CONTROL);
            break;
          case "MCONT":
            handleOnChangeOption(OptionApplicationMenu.CONTRACTUALES);
            break;
          case "MRCL":
            handleOnChangeOption(OptionApplicationMenu.RESUMEN_OPERACION);
            break;
        }
      }
    });
  }, []);

  return (
    <Grid container>
      <Grid size={10}>
        <Heimdall scopes={["MIGR", "SBPCG", "SBDASH", "SBFINA", "SBFLRE"]}>
          <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
            <Heimdall scopes={["MIGR"]}>
              <Typography
                p={1}
                borderRadius={1}
                sx={{
                  backgroundColor: optionSelected === OptionApplicationMenu.INFO_GRAL ? "#E3F2F8" : "",
                  cursor: "pointer",
                }}
                variant="h5"
                onClick={() => handleOnChangeOption(OptionApplicationMenu.INFO_GRAL)}
              >
                Info. General
              </Typography>
            </Heimdall>
            <Heimdall scopes={["SBPCG", "SBDASH", "SBFINA", "SBFLRE"]}>
              <Typography
                p={1}
                borderRadius={1}
                sx={{
                  backgroundColor: optionSelected === OptionApplicationMenu.PERFIL_CREDITICIO ? "#E3F2F8" : "",
                  cursor: "pointer",
                }}
                variant="h5"
                onClick={() => handleOnChangeOption(OptionApplicationMenu.PERFIL_CREDITICIO)}
              >
                Perfil Crediticio
              </Typography>
            </Heimdall>
          </Grid>
        </Heimdall>
        <Heimdall scopes={["SBEXEM", "SBEXAV", "SBDODI", "SBMODI"]}>
          <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
            <Heimdall scopes={["SBDODI", "SBMODI", "SBREPC"]}>
              <Typography
                p={1}
                borderRadius={1}
                sx={{
                  backgroundColor: optionSelected === OptionApplicationMenu.DICTAMEN_LEGAL ? "#E3F2F8" : "",
                  cursor: "pointer",
                }}
                variant="h5"
                onClick={() => handleOnChangeOption(OptionApplicationMenu.DICTAMEN_LEGAL)}
              >
                Dictamen Legal
              </Typography>
            </Heimdall>
            <Heimdall scopes={["SBEXEM", "SBEXAV"]}>
              <Typography
                p={1}
                borderRadius={1}
                sx={{
                  backgroundColor: optionSelected === OptionApplicationMenu.EXPEDIENTE ? "#E3F2F8" : "",
                  cursor: "pointer",
                }}
                variant="h5"
                onClick={() => handleOnChangeOption(OptionApplicationMenu.EXPEDIENTE)}
              >
                Expediente
              </Typography>
            </Heimdall>
          </Grid>
        </Heimdall>
        <Heimdall scopes={["MVCR", "SBPLD", "SBLEG"]}>
          <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
            <Heimdall scopes={["MVCR"]}>
              <Typography
                p={1}
                borderRadius={1}
                sx={{
                  backgroundColor: optionSelected === OptionApplicationMenu.VALIDACION_CREDITICA ? "#E3F2F8" : "",
                  cursor: "pointer",
                }}
                variant="h5"
                onClick={() => handleOnChangeOption(OptionApplicationMenu.VALIDACION_CREDITICA)}
              >
                Validación Crediticia
              </Typography>
            </Heimdall>
            <Heimdall scopes={["SBPLD", "SBLEG"]}>
              <Typography
                p={1}
                borderRadius={1}
                sx={{
                  backgroundColor: optionSelected === OptionApplicationMenu.LEGAL_PLD ? "#E3F2F8" : "",
                  cursor: "pointer",
                }}
                variant="h5"
                onClick={() => handleOnChangeOption(OptionApplicationMenu.LEGAL_PLD)}
              >
                PLD
              </Typography>
            </Heimdall>
          </Grid>
        </Heimdall>
        <Heimdall scopes={["SBVISI", "SBAVIS"]}>
          <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
            <Typography
              p={1}
              borderRadius={1}
              sx={{
                backgroundColor: optionSelected === OptionApplicationMenu.VISITA_OCULAR ? "#E3F2F8" : "",
                cursor: "pointer",
              }}
              variant="h5"
              onClick={() => handleOnChangeOption(OptionApplicationMenu.VISITA_OCULAR)}
            >
              Visita Ocular
            </Typography>
          </Grid>
        </Heimdall>
        <Heimdall scopes={["MCCO"]}>
          <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
            <Typography
              p={1}
              borderRadius={1}
              sx={{
                backgroundColor: optionSelected === OptionApplicationMenu.REVISION_CONTROL ? "#E3F2F8" : "",
                cursor: "pointer",
              }}
              variant="h5"
              onClick={() => handleOnChangeOption(OptionApplicationMenu.REVISION_CONTROL)}
            >
              Revisión Control
            </Typography>
          </Grid>
        </Heimdall>
        <Heimdall scopes={["MCON", "MDIS"]}>
          <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
            <Heimdall scopes={["MCON"]}>
              <Typography
                p={1}
                borderRadius={1}
                sx={{
                  backgroundColor: optionSelected === OptionApplicationMenu.CONTRACTUALES ? "#E3F2F8" : "",
                  cursor: "pointer",
                }}
                variant="h5"
                onClick={() => handleOnChangeOption(OptionApplicationMenu.CONTRACTUALES)}
              >
                Contractuales
              </Typography>
            </Heimdall>
            <Heimdall scopes={["MDIS"]}>
              <Typography
                p={1}
                borderRadius={1}
                sx={{
                  backgroundColor: optionSelected === OptionApplicationMenu.DISPERSION ? "#E3F2F8" : "",
                  cursor: "pointer",
                }}
                variant="h5"
                onClick={() => handleOnChangeOption(OptionApplicationMenu.DISPERSION)}
              >
                Cartera y Dispersión
              </Typography>
            </Heimdall>
          </Grid>
        </Heimdall>
        <Heimdall scopes={["MRCR"]}>
          <Grid borderRight={"1px solid #A3D4E8"} p={1}>
            <Typography
              p={1}
              borderRadius={1}
              sx={{
                backgroundColor: optionSelected === OptionApplicationMenu.RESUMEN_OPERACION ? "#E3F2F8" : "",
                cursor: "pointer",
              }}
              variant="h5"
              onClick={() => handleOnChangeOption(OptionApplicationMenu.RESUMEN_OPERACION)}
            >
              Resumen Operación
            </Typography>
          </Grid>
        </Heimdall>
        <Grid borderRight={"1px solid #A3D4E8"} p={1} minHeight={"50px"}></Grid>
      </Grid>
      <Grid size={"auto"}></Grid>
    </Grid>
  );
};
