import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";

import dayjs from "dayjs";
import "dayjs/locale/es-mx";

import { RootState } from "@store/store";
import { DialogType } from "@interfaces/slices";
import { generateColorRamp } from "@helpers/colors";
import { GraficoDonaGenerico } from "@components/GraficoDonaGenerico";
import { GraficoBarrasGenerico } from "@components/GraficoBarrasGenerico";
import { GraficoBarrasHorizontalGenerico } from "@components/GraficoBarrasHorizontalGenerico";
import { setDialogConfig, setPrintingDashboardReady, setPrintingMode } from "@store/slices/componentsSlice";

import {
  usePostMonthlyCashFlowMutation,
  usePostMonthlyCustomerSupplierInvoiceMutation,
  usePostMonthlyExpendituresMutation,
  usePostMonthlyProductsAnsServicesMutation,
  usePostMonthlySalesMutation,
} from "@services/api/sat_core";

import DownloadIcon from "@assets/download_icon.svg";

export const Dashboard = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [periodSelected, setPeriodSelected] = useState<number>(0);
  const [clusterSelected, setClusterSelected] = useState<number>(0);
  const [monthlyCashFlow, setMonthlyCashFlow] = useState<{
    inflow: any;
    outflow: any;
    months: Array<string>;
  }>({ months: [], inflow: {}, outflow: {} });

  const [topSales, setTopSales] = useState<Array<any>>([]);
  const [topCustomers, setTopCustomers] = useState<Array<any>>([]);
  const [sales, setSales] = useState<{ clusters: Array<string>; sales: any }>({ clusters: [], sales: {} });
  const [expenditures, setExpenditures] = useState<{ clusters: Array<string>; expenditures: any }>({
    clusters: [],
    expenditures: {},
  });

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const printingMode = useSelector((state: RootState) => state.components.printingProfileReport.printingMode);

  const [triggerPostSalesData, monthlySalesData] = usePostMonthlySalesMutation();
  const [triggerPostCashFlow, monthlyCashFlowData] = usePostMonthlyCashFlowMutation();
  const [triggerPostExpendituresData, monthlyExpendituresData] = usePostMonthlyExpendituresMutation();
  const [triggerPostMonthlyCustomerSupplierInvoice, monthlyCustomerSupplierInvoiceData] =
    usePostMonthlyCustomerSupplierInvoiceMutation();
  const [triggerPostMonthlyProductsAndServices, monthlyProductsAndServices] =
    usePostMonthlyProductsAnsServicesMutation();

  const monthNames = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

  const handleOnPeriodClicked = (value: number) => {
    setPeriodSelected(value);
    const endDate = dayjs().format("YYYY-MM-01");
    const startDate = dayjs()
      .subtract(11 * (value + 1) + value, "month")
      .format("YYYY-MM-01");

    requestMonthlyData(applicationSelected.rfc, startDate, endDate);
  };

  const handleOnClusterClicked = (value: number) => {
    setClusterSelected(value);

    const endDate = dayjs().format("YYYY-MM-01");
    const startDate = dayjs()
      .subtract(11 * (periodSelected + 1) + periodSelected, "month")
      .format("YYYY-MM-01");
    requestMonthlyData(applicationSelected.rfc, startDate, endDate);
  };

  const handleOnDownloadReportClicked = () => {
    dispatch(setPrintingMode(true));
    dispatch(
      setDialogConfig({
        shown: true,
        fullscreen: true,
        type: DialogType.DESCARGAR_REPORTE,
        props: undefined,
      }),
    );
  };

  const requestMonthlyData = async (rfc: string, startDate: string, endDate: string) => {
    try {
      setIsLoading(true);
      Promise.all([
        triggerPostSalesData({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
        triggerPostExpendituresData({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
        triggerPostCashFlow({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
        triggerPostMonthlyCustomerSupplierInvoice({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
        triggerPostMonthlyProductsAndServices({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
      ]).then(() => {
        setIsLoading(false);
        if (printingMode) {
          dispatch(setPrintingDashboardReady(true));
        }
      });
    } catch (error) {
      console.log("Error al consultar datos mensuales, %s", error);
      if (printingMode) {
        dispatch(setPrintingDashboardReady(true));
      }
    }
  };

  const getClusteringData = (data: any) => {
    let clusterData: any = {};
    data.forEach((sale: any) => {
      const year = sale.date.split("-")[0];
      const month = sale.date.split("-")[1];

      const key = clusterSelected === 3 ? "Q" : "S";
      const cluster = Math.ceil(Number(month) / clusterSelected);
      const clusterKey = clusterSelected !== 12 ? `${year}-${key}${cluster}` : `${year}`;

      if (!clusterData[clusterKey]) clusterData[clusterKey] = 0;

      clusterData[clusterKey] += sale.amount;
    });

    return clusterData;
  };

  useEffect(() => {
    if (monthlySalesData.isSuccess === false) return;

    let clusterData: any = {};
    if (clusterSelected !== 0) {
      clusterData = getClusteringData(monthlySalesData.data);
    }

    const sales = {
      data:
        clusterSelected === 0
          ? monthlySalesData.data.map((sale) => sale.amount)
          : Object.keys(clusterData).map((key) => clusterData[key]),
      backgroundColor: "rgb(0, 38, 84)",
      datalabels: {
        anchor: "end",
        align: "end",
        clamp: true,
        formatter: (value: any, _: any) => {
          return `$${(value / 1000000).toFixed(2)} M`;
        },
      },
    };

    const months = monthlySalesData.data.map((sale) => {
      const tmp = sale.date.split("-");
      return `${monthNames[Number(tmp[1]) - 1]} ${tmp[0]}`;
    });

    setSales({
      clusters: clusterSelected === 0 ? months : Object.keys(clusterData),
      sales: sales,
    });
  }, [monthlySalesData]);

  useEffect(() => {
    if (monthlyExpendituresData.isSuccess === false) return;

    let clusterData: any = {};
    if (clusterSelected !== 0) {
      clusterData = getClusteringData(monthlyExpendituresData.data);
    }

    const expenditures = {
      data:
        clusterSelected === 0
          ? monthlyExpendituresData.data.map((expenditure) => expenditure.amount)
          : Object.keys(clusterData).map((key) => clusterData[key]),
      backgroundColor: "rgb(0, 38, 84)",
      datalabels: {
        anchor: "end",
        align: "end",
        clamp: true,
        formatter: (value: any, _: any) => {
          return `$${(value / 1000000).toFixed(2)} M`;
        },
      },
    };

    const months = monthlyExpendituresData.data.map((expenditure) => {
      const tmp = expenditure.date.split("-");
      return `${monthNames[Number(tmp[1]) - 1]} ${tmp[0]}`;
    });

    setExpenditures({
      clusters: clusterSelected === 0 ? months : Object.keys(clusterData),
      expenditures: expenditures,
    });
  }, [monthlyExpendituresData]);

  useEffect(() => {
    if (monthlyCustomerSupplierInvoiceData.isSuccess === false) return;

    const topSales1 = {
      data: monthlyCustomerSupplierInvoiceData.data.suppliers.map((supplier) => supplier.total),
      backgroundColor: "rgb(0, 38, 84)",
      barThickness: 30,
      datalabels: {
        anchor: "center",
        align: "center",
        color: "rgb(255, 255, 255)",
      },
    };

    const topSales2 = {
      data: monthlyCustomerSupplierInvoiceData.data.suppliers.map((supplier) => supplier.share),
      backgroundColor: "rgb(0, 38, 84)",
      datalabels: {
        anchor: "end",
        clamp: true,
        align: "end",
        color: "rgb(0, 38, 84)",
      },
    };

    setTopSales([topSales1, topSales2]);

    const topCustomers1 = {
      data: monthlyCustomerSupplierInvoiceData.data.clients.map((client) => client.total),
      backgroundColor: "rgb(0, 38, 84)",
      barThickness: 30,
      datalabels: {
        anchor: "center",
        align: "center",
        color: "rgb(255, 255, 255)",
      },
    };

    const topCustomers2 = {
      data: monthlyCustomerSupplierInvoiceData.data.clients.map((client) => client.share),
      backgroundColor: "rgb(0, 38, 84)",
      datalabels: {
        anchor: "end",
        clamp: true,
        align: "end",
        color: "rgb(0, 38, 84)",
      },
    };

    setTopCustomers([topCustomers1, topCustomers2]);
  }, [monthlyCustomerSupplierInvoiceData]);

  useEffect(() => {
    if (monthlyCashFlowData.isSuccess === false) return;

    const startDates = [
      ...new Set(
        monthlyCashFlowData.data.map((cashFlow) => {
          return cashFlow.startDate;
        }),
      ),
    ];

    const inflowTotals = startDates.map((startDate) => {
      return monthlyCashFlowData.data
        .filter((cashFlow) => cashFlow.type === "inflow")
        .filter((inflow) => inflow.startDate === startDate)
        .reduce((acc, currentValue) => acc + currentValue.mxnAmount, 0);
    });

    let clusterInflowData: any = {};
    if (clusterSelected !== 0) {
      clusterInflowData = getClusteringData(
        startDates.map((startDate, idx) => {
          return {
            date: startDate,
            amount: inflowTotals[idx],
          };
        }),
      );
    }

    const outflowTotals = startDates.map((startDate) => {
      return monthlyCashFlowData.data
        .filter((cashFlow) => cashFlow.type === "outflow")
        .filter((outflow) => outflow.startDate === startDate)
        .reduce((acc, currentValue) => acc + currentValue.mxnAmount * -1, 0);
    });

    let clusterOutflowData: any = {};
    if (clusterSelected !== 0) {
      clusterOutflowData = getClusteringData(
        startDates.map((startDate, idx) => {
          return {
            date: startDate,
            amount: outflowTotals[idx],
          };
        }),
      );
    }

    const months = startDates.map((startDate) => {
      const tmp = startDate.split("-");
      return `${monthNames[Number(tmp[1]) - 1]} ${tmp[0]}`;
    });

    setMonthlyCashFlow({
      months: clusterSelected === 0 ? months : Object.keys(clusterInflowData),
      inflow: {
        data:
          clusterSelected === 0 ? inflowTotals : Object.keys(clusterInflowData).map((key) => clusterInflowData[key]),
        backgroundColor: "rgb(0, 38, 84)",
        datalabels: {
          anchor: "end",
          align: "end",
          clamp: true,
          offset: 0,
          formatter: (value: any, _: any) => {
            const valueFormatted = value / 1000000;
            if (valueFormatted === 0) return `$0 M`;
            return `$${valueFormatted.toFixed(2)} M`;
          },
        },
      },
      outflow: {
        data:
          clusterSelected === 0 ? outflowTotals : Object.keys(clusterOutflowData).map((key) => clusterOutflowData[key]),
        backgroundColor: "rgb(242, 112, 79)",
        datalabels: {
          anchor: "start",
          align: "start",
          clamp: true,
          formatter: (value: any, _: any) => {
            const valueFormatted = value / 1000000;
            if (valueFormatted === 0) return `$0 M`;
            return `$${valueFormatted.toFixed(2)} M`;
          },
        },
      },
    });
  }, [monthlyCashFlowData]);

  useEffect(() => {
    const endDate = dayjs().format("YYYY-MM-01");
    const startDate = dayjs().subtract(11, "month").format("YYYY-MM-01");

    requestMonthlyData(applicationSelected.rfc, startDate, endDate);
  }, []);

  return (
    <Grid container pt={1}>
      <Grid
        container
        width={"100%"}
        direction={"row"}
        justifyContent={"space-between"}
        display={printingMode ? "none" : ""}
      >
        <Grid display={"flex"} columnGap={2} alignItems={"center"} pl={2}>
          <Typography variant="body1" color="#002652" fontWeight={700}>
            Periodo
          </Typography>
          <Button
            onClick={() => handleOnPeriodClicked(0)}
            sx={{
              backgroundColor: periodSelected === 0 ? "#528CD6" : "#A3D4E8",
              color: periodSelected === 0 ? "#FFF" : "#000",
              fontSize: "13px",
              boxShadow: "8px 4px 20px rgba(82, 140, 214, 0.25)",
            }}
          >
            1 Año
          </Button>
          <Button
            onClick={() => handleOnPeriodClicked(1)}
            sx={{
              backgroundColor: periodSelected === 1 ? "#528CD6" : "#A3D4E8",
              color: periodSelected === 1 ? "#FFF" : "#000",
              fontSize: "13px",
              boxShadow: "8px 4px 20px rgba(82, 140, 214, 0.25)",
            }}
          >
            2 Años
          </Button>
          <Button
            onClick={() => handleOnPeriodClicked(2)}
            sx={{
              backgroundColor: periodSelected === 2 ? "#528CD6" : "#A3D4E8",
              color: periodSelected === 2 ? "#FFF" : "#000",
              fontSize: "13px",
              boxShadow: "8px 4px 20px rgba(82, 140, 214, 0.25)",
            }}
          >
            3 Años
          </Button>
        </Grid>
        <Grid display={"flex"} columnGap={2} alignItems={"center"}>
          <Typography variant="body1" color="#002652" fontWeight={700}>
            Agrupación
          </Typography>
          <Button
            onClick={() => handleOnClusterClicked(0)}
            sx={{
              backgroundColor: clusterSelected === 0 ? "#528CD6" : "#A3D4E8",
              color: clusterSelected === 0 ? "#FFF" : "#000",
              fontSize: "13px",
              boxShadow: "8px 4px 20px rgba(82, 140, 214, 0.25)",
            }}
          >
            Mensual
          </Button>
          <Button
            onClick={() => handleOnClusterClicked(3)}
            sx={{
              backgroundColor: clusterSelected === 3 ? "#528CD6" : "#A3D4E8",
              color: clusterSelected === 3 ? "#FFF" : "#000",
              fontSize: "13px",
              boxShadow: "8px 4px 20px rgba(82, 140, 214, 0.25)",
            }}
          >
            Trimestral
          </Button>
          <Button
            onClick={() => handleOnClusterClicked(6)}
            sx={{
              backgroundColor: clusterSelected === 6 ? "#528CD6" : "#A3D4E8",
              color: clusterSelected === 6 ? "#FFF" : "#000",
              fontSize: "13px",
              boxShadow: "8px 4px 20px rgba(82, 140, 214, 0.25)",
            }}
          >
            Semestral
          </Button>
          <Button
            onClick={() => handleOnClusterClicked(12)}
            sx={{
              backgroundColor: clusterSelected === 12 ? "#528CD6" : "#A3D4E8",
              color: clusterSelected === 12 ? "#FFF" : "#000",
              fontSize: "13px",
              boxShadow: "8px 4px 20px rgba(82, 140, 214, 0.25)",
            }}
          >
            Anual
          </Button>
        </Grid>
        <Grid>
          <Button
            endIcon={<img src={DownloadIcon} />}
            variant="text"
            sx={{ color: "#528CD6" }}
            onClick={handleOnDownloadReportClicked}
          >
            Descargar Reporte
          </Button>
        </Grid>
      </Grid>
      <Grid p={2} width={"100%"}>
        <GraficoBarrasGenerico
          isLoading={isLoading}
          title="Ventas"
          labels={sales.clusters}
          datasets={[sales.sales]}
          showDataLabels
        />
      </Grid>
      <Grid p={2} mt={2} width={"100%"}>
        <GraficoBarrasGenerico
          isLoading={isLoading}
          title="Gastos"
          labels={expenditures.clusters}
          datasets={[expenditures.expenditures]}
          showDataLabels
        />
      </Grid>
      <Grid p={2} mt={2} width={"100%"}>
        <GraficoBarrasGenerico
          isLoading={isLoading}
          stacked
          title="Flujo"
          subtitle="(Ingreso, Egresos)"
          labels={monthlyCashFlow.months}
          datasets={[monthlyCashFlow.inflow, monthlyCashFlow.outflow]}
          showDataLabels
        />
      </Grid>
      <Grid p={2} mt={2} width={"100%"} container>
        <Grid size={12} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}>
          <Typography variant="h3" fontWeight={700} color="#002652" mb={2}>
            Clientes y Proveedores
          </Typography>
        </Grid>
        <Grid size={6} mt={1}>
          <Box borderRight={"1px solid rgba(163, 212, 232, 0.5)"} px={2}>
            <Typography variant="h4" fontWeight={600} color={"#002652"}>
              Top Clientes
            </Typography>
            <GraficoBarrasHorizontalGenerico
              isLoading={isLoading}
              labels={
                monthlyCustomerSupplierInvoiceData.data?.clients.map((client) => {
                  if (client.name.length >= 25) {
                    const middle = Math.floor(client.name.length / 2);
                    return [client.name.slice(0, middle).toUpperCase(), client.name.slice(middle).toUpperCase()];
                  }
                  return [client.name];
                }) ?? []
              }
              datasets={topCustomers}
              color={"rgb(255, 255, 255)"}
              showDataLabels
              labelFormatter={(value, ctx) => {
                if (ctx.datasetIndex == 0) {
                  const percentage = ctx.chart.data.datasets[1].data[ctx.dataIndex];
                  if (percentage >= 10) {
                    return `$${(Number(value) / 1000000).toFixed(2)} M`;
                  }
                  return "";
                } else {
                  return `${value}%`;
                }
              }}
              stacked
            />
          </Box>
        </Grid>
        <Grid size={6} mt={1}>
          <Box px={2}>
            <Typography variant="h4" fontWeight={600} color={"#002652"}>
              Top Proveedores
            </Typography>

            <GraficoBarrasHorizontalGenerico
              isLoading={isLoading}
              labels={
                monthlyCustomerSupplierInvoiceData.data?.suppliers.map((supplier) => {
                  if (supplier.name.length >= 25) {
                    const middle = Math.floor(supplier.name.length / 2);
                    return [supplier.name.slice(0, middle).toUpperCase(), supplier.name.slice(middle).toUpperCase()];
                  }
                  return [supplier.name];
                }) ?? []
              }
              datasets={topSales}
              color={"rgb(255, 255, 255)"}
              showDataLabels
              labelFormatter={(value, ctx) => {
                if (ctx.datasetIndex == 0) {
                  const percentage = ctx.chart.data.datasets[1].data[ctx.dataIndex];
                  if (percentage >= 5) {
                    return `$${(Number(value) / 1000000).toFixed(2)} M`;
                  }
                  return "";
                } else {
                  return `${value}%`;
                }
              }}
              stacked
            />
          </Box>
        </Grid>
      </Grid>
      <Grid p={2} mt={2} width={"100%"} container>
        <Grid size={12} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}>
          <Typography variant="h3" fontWeight={700} color="#002652" mb={2}>
            Productos y Servicios
          </Typography>
        </Grid>
        <Grid size={6} mt={1}>
          <Box borderRight={"1px solid rgba(163, 212, 232, 0.5)"}>
            <GraficoDonaGenerico
              isLoading={isLoading}
              title="Vendidos"
              data={
                monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.sold.map((t) => t.total) : []
              }
              labels={
                monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.sold.map((t) => t.name) : []
              }
              colors={generateColorRamp(
                "#101e3a",
                "#b0c3ea",
                monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.sold.length : 0,
              )}
              labelFormatter={(value, ctx) => {
                return `$${(Number(value) / 1000000).toFixed(2)} M\n${
                  monthlyProductsAndServices.data?.sold[ctx.dataIndex].share
                }%`;
              }}
            />
          </Box>
        </Grid>
        <Grid size={6} mt={1}>
          <Box>
            <GraficoDonaGenerico
              isLoading={isLoading}
              title="Comprados"
              data={
                monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.bought.map((t) => t.total) : []
              }
              labels={
                monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.bought.map((t) => t.name) : []
              }
              colors={[
                ...generateColorRamp(
                  "#101e3a",
                  "#b0c3ea",
                  monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.sold.length - 2 : 0,
                ),
                "#ffc9c9",
              ]}
              labelFormatter={(value, ctx) => {
                return `$${(Number(value) / 1000000).toFixed(2)} M\n${
                  monthlyProductsAndServices.data?.bought[ctx.dataIndex].share
                }%`;
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
