import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PopupConfig, PopupType, Shareholder } from "@interfaces/slices";
import { DocumentInfo } from "@interfaces/documents";
import { DialogConfig, ButtonType, OptionApplicationMenu, DocumentAction, DialogType } from "@interfaces/slices";
import { UUID } from "crypto";

export interface Components {
  dialog: DialogConfig;
  popup: PopupConfig;
  buttonPanel: {
    buttons: Array<{ btn: ButtonType; disabled: boolean; onClick?: () => void; text?: string }>;
    buttonClicked: {
      btn: ButtonType | undefined;
      clicked: boolean;
    };
  };
  menuApplication: {
    optionSelected: OptionApplicationMenu;
  };
  documentAction: {
    action: DocumentAction | undefined;
    doc: DocumentInfo | undefined;
  };
  printingProfileReport: {
    printingMode: boolean;
    module: {
      generalReady: boolean;
      downloadSatReady: boolean;
      profileReady: boolean;
      dashboardReady: boolean;
      financialReady: boolean;
    };
  };
  shareholdersStructure: {
    shareholders: Array<Shareholder>;
    editingMode: boolean;
  };
  usersList: {
    editingMode: boolean;
    userSelected: {
      id: UUID;
      name: string;
      lastName: string;
      lastName2: string;
    };
  };
  opinionModule: {
    formData: {
      publicDeedNumber: string;
      publicDeedDate: string;
      notaryOfficeNumber: string;
      notaryName: string;
      municipality: string;
      federative: string;
      sufficientPowers: boolean | null;
      limitationContractCredit: boolean | null;
      commissionerName: string;
      commissionerLastName: string;
      commissionerLastName2: string;
      majorityShareholderValidate: boolean | null;
      legalAgentName: string;
      legalAgentLastName: string;
      legalAgentLastName2: string;
      legalAgentIsShareholder: boolean | null;
      legalAgentCharge: string;
      legalAgentChargeOption: number;
    };
    legalAgentId: UUID;
    majorityShareholderId: UUID;
  };
}

const initialState: Components = {
  dialog: {
    shown: false,
    type: DialogType.NONE,
    props: undefined,
    onRejectClick: () => {},
    onAcceptClick: () => {},
    onCloseClick: () => {},
  },
  popup: {
    type: PopupType.NONE,
    props: undefined,
    anchorEl: undefined,
  },
  buttonPanel: {
    buttons: [],
    buttonClicked: {
      clicked: false,
      btn: undefined,
    },
  },
  menuApplication: {
    optionSelected: OptionApplicationMenu.INFO_GRAL,
  },
  documentAction: {
    action: DocumentAction.SEE,
    doc: undefined,
  },
  printingProfileReport: {
    printingMode: false,
    module: {
      generalReady: false,
      downloadSatReady: false,
      profileReady: false,
      dashboardReady: false,
      financialReady: false,
    },
  },
  shareholdersStructure: {
    shareholders: [],
    editingMode: false,
  },
  usersList: {
    editingMode: false,
    userSelected: {
      id: "0-0-0-0-0",
      name: "",
      lastName: "",
      lastName2: "",
    },
  },
  opinionModule: {
    formData: {
      publicDeedNumber: "",
      publicDeedDate: "",
      notaryOfficeNumber: "",
      notaryName: "",
      municipality: "",
      federative: "",
      sufficientPowers: null,
      limitationContractCredit: null,
      commissionerName: "",
      commissionerLastName: "",
      commissionerLastName2: "",
      majorityShareholderValidate: null,
      legalAgentName: "",
      legalAgentLastName: "",
      legalAgentLastName2: "",
      legalAgentIsShareholder: null,
      legalAgentCharge: "",
      legalAgentChargeOption: 0,
    },
    legalAgentId: "0-0-0-0-0",
    majorityShareholderId: "0-0-0-0-0",
  },
};

export const componentsSlice = createSlice({
  name: "components",
  initialState,
  reducers: {
    setDialogConfig: (state, action: PayloadAction<DialogConfig>) => {
      state.dialog = action.payload;
    },
    setDialogConfigProcessing: (state, action: PayloadAction<boolean>) => {
      state.dialog.isProcessing = action.payload;
    },
    setVisibleButtons: (
      state,
      action: PayloadAction<Array<{ btn: ButtonType; disabled: boolean; onClick?: () => void; text?: string }>>,
    ) => {
      state.buttonPanel.buttons = action.payload;
    },
    setOptionAppSelected: (state, action: PayloadAction<OptionApplicationMenu>) => {
      state.menuApplication.optionSelected = action.payload;
    },
    setDocumentAction: (state, action: PayloadAction<{ action: DocumentAction; doc: DocumentInfo }>) => {
      state.documentAction = action.payload;
    },
    setButtonClicked: (state, action: PayloadAction<{ btn: ButtonType | undefined; clicked: boolean }>) => {
      state.buttonPanel.buttonClicked = action.payload;
    },
    setPrintingMode: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.printingMode = action.payload;
    },
    setPrintingGeneralReady: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.module.generalReady = action.payload;
    },
    setPrintingDownloadSatReady: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.module.downloadSatReady = action.payload;
    },
    setPrintingProfileReady: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.module.profileReady = action.payload;
    },
    setPrintingDashboardReady: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.module.dashboardReady = action.payload;
    },
    setPrintingFinancialReady: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.module.financialReady = action.payload;
    },
    setPrintingReset: (state) => {
      state.printingProfileReport = {
        printingMode: false,
        module: {
          generalReady: false,
          downloadSatReady: false,
          profileReady: false,
          dashboardReady: false,
          financialReady: false,
        },
      };
    },
    setShareholders: (state, action: PayloadAction<Array<Shareholder>>) => {
      state.shareholdersStructure.shareholders = action.payload;
    },
    setShareholderEditingMode: (state, action: PayloadAction<boolean>) => {
      state.shareholdersStructure.editingMode = action.payload;
    },
    setPopupConfig: (state, action: PayloadAction<PopupConfig>) => {
      state.popup = action.payload;
    },
    setUsersListEditingMode: (state, action: PayloadAction<boolean>) => {
      state.usersList.editingMode = action.payload;
    },
    setUserListSelected: (state, action: PayloadAction<any | undefined>) => {
      if (action.payload === undefined) {
        state.usersList.userSelected = initialState.usersList.userSelected;
      } else {
        state.usersList.userSelected = action.payload;
      }
    },
    setOpinionModuleValues: (state, action: PayloadAction<any>) => {
      state.opinionModule.formData = action.payload;
    },
    setOpinionModuleLegalAgentId: (state, action: PayloadAction<UUID>) => {
      state.opinionModule.legalAgentId = action.payload;
    },
    setOpinionModuleMajorityShareholder: (state, action: PayloadAction<UUID>) => {
      state.opinionModule.majorityShareholderId = action.payload;
    },
  },
});

export const {
  setDialogConfig,
  setDialogConfigProcessing,
  setVisibleButtons,
  setOptionAppSelected,
  setDocumentAction,
  setButtonClicked,
  setPrintingMode,
  setPrintingGeneralReady,
  setPrintingDownloadSatReady,
  setPrintingProfileReady,
  setPrintingDashboardReady,
  setPrintingFinancialReady,
  setPrintingReset,
  setShareholders,
  setShareholderEditingMode,
  setPopupConfig,
  setUsersListEditingMode,
  setUserListSelected,
  setOpinionModuleValues,
  setOpinionModuleLegalAgentId,
  setOpinionModuleMajorityShareholder,
} = componentsSlice.actions;
export default componentsSlice.reducer;
