import { UUID } from "crypto";

import { Company } from "@interfaces/companies";
import { ApplicationSelected } from "@interfaces/slices";
import { BpmUser, Permission } from "@interfaces/users";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppSlice {
  applicationSelected: ApplicationSelected;
  session: {
    user: BpmUser;
    companies: Array<Company>;
    permissions: Array<Permission>;
    recovery: {
      username: string;
    };
    source_codes: Array<string>;
  };
  validateFileModal: {
    show: boolean;
  };
}

const initialState: AppSlice = {
  applicationSelected: {
    id: undefined,
    solicitudId: 0,
    razonSocial: "",
    nombreComercial: "",
    rfc: "",
    nombre: "",
    montoSolicitado: "",
    plazoSolicitado: 0,
    tiempoPantalla: 0,
    tiempoTotal: 0,
    etapa: "",
    subEtapa: "",
    estatus: "",
    pantalla: "",
    macroCanal: "",
    canal: "",
    asignacion: "",
    perdioInteres: 0,
    montoContratado: "",
    plazoContratado: 0,
    tiempoTotalProceso: "",
    dictamenCompletado: false,
    fullData: "",
    company_docs_validated: false,
    shareholder_docs_validated: false,
    owners_docs_validated: false,
    canalId: "",
    correoEjecutivo: "",
    tax_systems: {
      id: undefined,
      code: "",
      name: "",
      type: "",
    },
    status_code: "",
    existsSDC: false,
    montoSolicitadoOriginal: 0,
    plazoSolicitadoOriginal: 0,
    usuario: {
      nombre: "",
      correo: "",
    },
    accionistaMayoritario: {
      nombre: "",
      rfc: "",
    },
    clientDetailId: undefined,
    productId: undefined,
    statusId: undefined,
    fechaModelo: "",
    montoContratadoOriginal: 0,
    plazoContratadoOriginal: 0,
    tasaContratadoOriginal: 0,
    loanApplicationConditionActive: {
      id: undefined,
    },
    detalleCanal: "",
    credit_validated: null,
    pld_validated: null,
    legal_incidents_validated: null,
    visit_analysis_validated: null,
    check_control_validated: null,
  },
  session: {
    user: {
      id: "0-0-0-0-0",
      name: "",
      last_name: "",
      last_name_2: "",
      rol_id: "0-0-0-0-0",
      created_at: "",
      updated_at: "",
      role: {
        id: "0-0-0-0-0",
        name: "",
        code: "",
      },
    },
    permissions: [],
    recovery: {
      username: "",
    },
    companies: [],
    source_codes: [],
  },
  validateFileModal: {
    show: false,
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setApplicationSelected: (state, action: PayloadAction<ApplicationSelected>) => {
      state.applicationSelected = action.payload;
    },
    setCleanApplicationSelected: (state) => {
      state.applicationSelected = initialState.applicationSelected;
    },
    setApplicationCompleted: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.dictamenCompletado = action.payload;
    },
    setApplicationState: (state, action: PayloadAction<{ code: string; estatus: string; statusId: UUID }>) => {
      state.applicationSelected.statusId = action.payload.statusId;
      state.applicationSelected.status_code = action.payload.code;
      state.applicationSelected.estatus = action.payload.estatus;
    },
    setApplicationStateCode: (state, action: PayloadAction<string>) => {
      state.applicationSelected.status_code = action.payload;
    },
    setBpmUserLogged: (state, action: PayloadAction<BpmUser>) => {
      state.session.user = action.payload;
    },
    setUserPermissions: (state, action: PayloadAction<Array<Permission>>) => {
      state.session.permissions = action.payload;
    },
    setCompaniesAssigned: (state, action: PayloadAction<Array<Company>>) => {
      state.session.companies = action.payload;
    },
    setSourceCodesAssigned: (state, action: PayloadAction<Array<string>>) => {
      state.session.source_codes = action.payload;
    },
    setCompanyDocsValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.company_docs_validated = action.payload;
    },
    setShareHolderDocsValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.shareholder_docs_validated = action.payload;
    },
    setOwnersDocsValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.owners_docs_validated = action.payload;
    },
    setShowValidateFileModal: (state, action: PayloadAction<boolean>) => {
      state.validateFileModal.show = action.payload;
    },
    setRecoveryUsername: (state, action: PayloadAction<string>) => {
      state.session.recovery.username = action.payload;
    },
    setExistSDC: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.existsSDC = action.payload;
    },
    setApplicationCreditValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.credit_validated = action.payload;
    },
    setApplicationPldValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.pld_validated = action.payload;
    },
    setApplicationLegalIncidentValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.legal_incidents_validated = action.payload;
    },
    setApplicationVisitAnalysisValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.visit_analysis_validated = action.payload;
    },
    setApplicationCheckControlValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.check_control_validated = action.payload;
    },
    setApplicationCanalIdUpdated: (state, action: PayloadAction<string>) => {
      state.applicationSelected.canalId = action.payload;
    },
  },
});

export const {
  setCleanApplicationSelected,
  setApplicationSelected,
  setApplicationCompleted,
  setApplicationState,
  setApplicationStateCode,
  setBpmUserLogged,
  setUserPermissions,
  setCompaniesAssigned,
  setSourceCodesAssigned,
  setCompanyDocsValidated,
  setShareHolderDocsValidated,
  setOwnersDocsValidated,
  setShowValidateFileModal,
  setRecoveryUsername,
  setExistSDC,
  setApplicationCreditValidated,
  setApplicationPldValidated,
  setApplicationLegalIncidentValidated,
  setApplicationVisitAnalysisValidated,
  setApplicationCheckControlValidated,
  setApplicationCanalIdUpdated,
} = appSlice.actions;
export default appSlice.reducer;
