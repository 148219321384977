import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Divider,
  Fade,
  Grid2 as Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { RootState } from "@store/store";

import { DialogType } from "@interfaces/slices";
import { EstatusAbortivos } from "@components/EstatusAbortivos";
import { formatCurrency, formatYearsV2 } from "@helpers/formats";
import { createRowTable, createSimpleRow } from "@helpers/components";
import { useLazyGetCompanyProfileQuery, useLazyGetCSFQuery } from "@services/api/applications";
import { EstatusRazonesRevision } from "@components/EstatusRazonesRevision";
import { CategoriasAnalisisGrafico } from "@components/CategoriasAnalisisGrafico";
import { useGetUrlToDownloadDocMutation, useLazyGetDocumentsQuery } from "@services/api/documents";
import { setDialogConfig, setPrintingMode, setPrintingProfileReady } from "@store/slices/componentsSlice";

import SeeIcon from "@assets/see_icon.svg";
import ExcelIcon from "@assets/excel_icon.svg";
import DownloadIcon from "@assets/download_icon.svg";
import InformativeIcon from "@assets/informative_icon.svg";

interface PerfilProps {
  showOnlyCategoryAndVariables?: boolean;
}

export const Perfil = (props: PerfilProps) => {
  const dispatch = useDispatch();

  const isMdDown = useMediaQuery("(min-width:1300px)");
  const [values, setValues] = useState<Array<number>>([]);
  const [seeDocAction, setSeeDocAction] = useState<string | null>(null);
  const [downloadDocAction, setDownloadDocAction] = useState<string | null>(null);
  const [fiscalAddress, setFiscalAddress] = useState<string>("");
  const [fiscalNumber, setFiscalNumber] = useState<string>("");
  const [fiscalColonia, setFiscalColonia] = useState<string>("");
  const [fiscalState, setFiscalState] = useState<string>("");
  const [fiscalLocality, setFiscalLocality] = useState<string>("");
  const [fiscalPostalCode, setFiscalPostalCode] = useState<string>("");

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const printingMode = useSelector((state: RootState) => state.components.printingProfileReport.printingMode);

  const [triggerGetDocs] = useLazyGetDocumentsQuery();
  const [triggerGetCertificateTaxStatus] = useLazyGetCSFQuery();
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();

  const [triggerGetCompanyProfile, companyProfile] = useLazyGetCompanyProfileQuery();

  const handleOnDownloadReportClicked = () => {
    dispatch(setPrintingMode(true));
    dispatch(
      setDialogConfig({
        shown: true,
        fullscreen: true,
        type: DialogType.DESCARGAR_REPORTE,
        props: undefined,
      }),
    );
  };

  const handleOnSeeDocClicked = async (docType: string) => {
    setSeeDocAction(docType);
    try {
      const currentDocs = await triggerGetDocs({
        loan_application_id: applicationSelected.id,
        client_details_id: applicationSelected.clientDetailId,
      }).unwrap();

      const existFile = currentDocs.find((doc) => doc.file_code === docType && doc.status === true);
      if (existFile) {
        const urlDownload = await triggerPostUrlDocument(existFile.url);
        window.open(urlDownload.data, "_blank");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSeeDocAction(null);
    }
  };

  const handleOnDownloadDocClicked = async (docType: string) => {
    setDownloadDocAction(docType);
    try {
      const currentDocs = await triggerGetDocs({
        loan_application_id: applicationSelected.id,
        client_details_id: applicationSelected.clientDetailId,
      }).unwrap();

      const existFile = currentDocs.find((doc) => doc.file_code === docType && doc.status === true);
      if (existFile) {
        const urlDownload = await triggerPostUrlDocument(existFile.url);
        const response = await fetch(urlDownload.data ?? "");
        const blob = await response.blob();

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("target", "_blank");
        link.setAttribute("download", existFile.file_name);

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDownloadDocAction(null);
    }
  };

  const reloadInformation = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("q", `rfc:${applicationSelected.rfc}`);
    const certificateTaxStatus = await triggerGetCertificateTaxStatus(searchParams.toString()).unwrap();

    const fiscalAddressData = certificateTaxStatus.at(0)?.data.address;
    setFiscalAddress(`${fiscalAddressData?.streetType} ${fiscalAddressData?.streetName}`);
    setFiscalNumber(
      `${fiscalAddressData?.streetNumber} ${fiscalAddressData?.buildingNumber ? "-" : ""} ${
        fiscalAddressData?.buildingNumber
      }`,
    );
    setFiscalColonia(`${fiscalAddressData?.neighborhood}`);
    setFiscalLocality(`${fiscalAddressData?.locality}`);
    setFiscalState(`${fiscalAddressData?.state}`);
    setFiscalPostalCode(`${fiscalAddressData?.postalCode}`);

    await triggerGetCompanyProfile({
      loanApplicationId: applicationSelected.id ?? "0-0-0-0-0",
      rfc: applicationSelected.rfc,
    }).unwrap();
  };

  useEffect(() => {
    if (companyProfile.isSuccess && companyProfile.currentData) {
      if (companyProfile.data.analysis_categories === undefined || companyProfile.data.analysis_categories == null)
        return;

      const keys = Object.keys(companyProfile.data.analysis_categories).filter(
        (t) => ["csf_others", "total"].some((a) => a === t) === false,
      );

      const tmp = keys.map((key: string) => companyProfile.data.analysis_categories[key]);
      setValues(tmp);
    }

    if (printingMode) {
      dispatch(setPrintingProfileReady(companyProfile.isSuccess));
    }
  }, [companyProfile]);

  useEffect(() => {
    if (applicationSelected.id === "0-0-0-0-0" || applicationSelected.id === undefined) return;

    reloadInformation();
  }, [applicationSelected.id]);

  return (
    <Grid container pt={1}>
      <Grid
        size={12}
        textAlign={"end"}
        mb={2}
        display={printingMode || props.showOnlyCategoryAndVariables ? "none" : "flex-end"}
      >
        <Button
          endIcon={<img src={DownloadIcon} />}
          variant="text"
          sx={{ color: "#528CD6" }}
          onClick={handleOnDownloadReportClicked}
        >
          Descargar Reporte
        </Button>
      </Grid>
      {printingMode && <Grid size={12} my={4}></Grid>}
      <Grid container direction={"column"} rowSpacing={2} display={props.showOnlyCategoryAndVariables ? "none" : ""}>
        <Grid>
          <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
            Abortivos
          </Typography>
          <EstatusAbortivos />
        </Grid>
        <Grid>
          <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
            Razones de Revisión
          </Typography>
          <EstatusRazonesRevision />
        </Grid>
      </Grid>
      <Grid container mt={6} size={12} display={props.showOnlyCategoryAndVariables ? "none" : ""}>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
          Resultado Modelos
        </Typography>
        <Grid container size={12}>
          {createRowTable(
            "Calificación Score Lendia",
            companyProfile.currentData?.result_model?.score_code ?? "",
            "Resultado Externo",
            companyProfile.currentData?.result_external_model.result ? "Rechazado" : "Autorizado",
          )}
        </Grid>
      </Grid>
      <Grid container direction={"column"} mt={6} display={props.showOnlyCategoryAndVariables ? "none" : ""}>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
          Perfil Empresa
        </Typography>
        <Grid container>
          {createRowTable(
            "Ventas Anuales Facturadas",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details?.annual_sales_invoiced ?? "0"),
              "$",
              0,
            ),
            "Compras Anuales Facturadas",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details?.annual_expenses_invoiced ?? "0"),
              "$",
              0,
            ),
          )}
          {createRowTable(
            "Ingresos Anuales Facturados",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details?.annual_revenues_invoiced ?? "0"),
              "$",
              0,
            ),
            "Egresos Anuales Facturados",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details?.annual_expenses_invoiced ?? "0"),
              "$",
              0,
            ),
          )}
          {createRowTable(
            "Número de Empleados",
            companyProfile.currentData?.general_company_details?.number_employees ?? "0",
            "Nómina Mensual",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details?.monthly_payroll ?? "0"),
              "$",
              0,
            ),
          )}
          {createRowTable(
            "Score Pyme (Empresa)",
            companyProfile.currentData?.general_company_details?.score_company == "-1"
              ? "Sin información"
              : companyProfile.currentData?.general_company_details.score_company ?? "0",
            "BC Score (Persona)",
            companyProfile.currentData?.general_company_details?.bc_score == "-1"
              ? "Sin información"
              : companyProfile.currentData?.general_company_details.bc_score ?? "0",
          )}
          {createRowTable(
            "Capital Contable",
            formatCurrency(companyProfile.currentData?.general_company_details.equity ?? 0, "$", 2),
            "Ingreso o Perdida Declarada",
            formatCurrency(companyProfile.currentData?.general_company_details?.declared_income_loss ?? 0, "$", 0),
          )}
          {createRowTable(
            "Estatus Listas Negras",
            companyProfile.currentData?.general_company_details?.status_backlists == "-1"
              ? "Sin información"
              : companyProfile.currentData?.general_company_details.status_backlists ?? "Ninguno",
            "Contrapartes Listas Negras",
            companyProfile.currentData?.general_company_details?.blacklists == "-1"
              ? "Sin información"
              : companyProfile.currentData?.general_company_details.blacklists ?? "Ninguno",
          )}
          <Grid size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
            <div>
              <Grid container>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Antigüedad, CSF
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }} container sx={{ alignItems: "center" }}>
                  <Typography color={"#002652"}>
                    {formatYearsV2(parseFloat(companyProfile.currentData?.general_company_details?.seniority ?? "0"))}
                  </Typography>
                  {printingMode === false && (
                    <>
                      <IconButton
                        sx={{ p: 0, mx: seeDocAction === "DCDF" ? 2.5 : 1 }}
                        onClick={() => handleOnSeeDocClicked("DCDF")}
                        loading={seeDocAction === "DCDF"}
                      >
                        {seeDocAction !== "DCDF" && <img src={SeeIcon} />}
                      </IconButton>
                      <IconButton
                        sx={{ p: 0, mx: downloadDocAction === "DCDF" ? 2.5 : 1 }}
                        onClick={() => handleOnDownloadDocClicked("DCDF")}
                        loading={downloadDocAction === "DCDF"}
                      >
                        {downloadDocAction !== "DCDF" && <img src={DownloadIcon} />}
                      </IconButton>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            size={{ xs: 6 }}
            p={1}
            borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
            borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
          >
            <div>
              <Grid container ml={4}>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Opinión de cumplimiento
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }} container sx={{ alignItems: "center" }}>
                  <Typography color={"#002652"}>
                    {(companyProfile.currentData?.general_company_details?.compliance_opinion ?? "") === "negative"
                      ? "Negativo"
                      : "Positivo"}
                  </Typography>
                  {printingMode === false && (
                    <>
                      <IconButton
                        sx={{ p: 0, mx: seeDocAction === "FCCOC" ? 2.5 : 1 }}
                        onClick={() => handleOnSeeDocClicked("FCCOC")}
                        loading={seeDocAction === "FCCOC"}
                      >
                        {seeDocAction !== "FCCOC" && <img src={SeeIcon} />}
                      </IconButton>
                      <IconButton
                        sx={{ p: 0, mx: seeDocAction === "FCCOC" ? 2.5 : 1 }}
                        onClick={() => handleOnDownloadDocClicked("FCCOC")}
                        loading={downloadDocAction === "FCCOC"}
                      >
                        {downloadDocAction !== "FCCOC" && <img src={DownloadIcon} />}
                      </IconButton>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          {createSimpleRow(
            "Actividad Preponderante (%)",
            companyProfile.currentData?.general_company_details?.predominant_activity ?? "",
          )}
          {createSimpleRow("Uso del crédito", companyProfile.currentData?.credit_use ?? "")}
        </Grid>
      </Grid>
      <Grid container size={12} direction={"column"} mt={6} display={props.showOnlyCategoryAndVariables ? "none" : ""}>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
          Dirección Fiscal Empresa
        </Typography>
        <Grid container>
          {createRowTable("Calle - Dirección Fiscal", fiscalAddress, "Numero Exterior - Interior", fiscalNumber)}
          {createRowTable("Colonia", fiscalColonia, "Municipio", fiscalLocality)}
          {createRowTable("Entidad Federativa", fiscalState, "Codigo Postal", fiscalPostalCode)}
        </Grid>
      </Grid>
      <Grid container mt={6} size={12} display={props.showOnlyCategoryAndVariables ? "none" : ""}>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
          Riesgo Fraude Usuario
        </Typography>
        <Grid container size={12}>
          <Grid size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
            <div>
              <Grid container>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Score Riesgo Correo{" "}
                    <Tooltip
                      placement="top-start"
                      title={
                        <>
                          <Typography component={"span"} variant="body2" fontWeight={700} color="">
                            Score Riesgo del usuario:{" "}
                            <Typography component={"span"} variant="body2" fontWeight={400}>
                              De 0 a 100, donde arriba de 80 representa un riesgo significativo a ser tomado en cuenta.
                            </Typography>
                          </Typography>
                        </>
                      }
                      slots={{ transition: Fade }}
                      slotProps={{ transition: { timeout: 600 } }}
                    >
                      <img src={InformativeIcon} />
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Typography color={"#002652"}>{""}</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            size={{ xs: 6 }}
            p={1}
            borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
            borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
          >
            <div>
              <Grid container ml={4}>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Score Riesgo Teléfono{" "}
                    <Tooltip
                      placement="top-start"
                      title={
                        <>
                          <Typography component={"span"} variant="body2" fontWeight={700} color="">
                            Score Riesgo del usuario:{" "}
                            <Typography component={"span"} variant="body2" fontWeight={400}>
                              De 0 a 100, donde arriba de 80 representa un riesgo significativo a ser tomado en cuenta.
                            </Typography>
                          </Typography>
                        </>
                      }
                      slots={{ transition: Fade }}
                      slotProps={{ transition: { timeout: 600 } }}
                    >
                      <img src={InformativeIcon} />
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Typography color={"#002652"}>{""}</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction={"column"} mt={6} size={12} display={props.showOnlyCategoryAndVariables ? "none" : ""}>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
          Información Laboral Aval
        </Typography>
        <Grid container>
          {createRowTable("Accionista Mayoritario", "", "Ultima Empresa Laboral", "")}
          {createRowTable(
            "Ultimo Sueldo Mensual",
            formatCurrency(0, "$", 0),
            "Promedio Sueldo",
            formatCurrency(0, "$", 0),
          )}
          {createRowTable(
            "Fecha Inicio ultimo Empleo",
            "",
            <>
              Fecha Cierre ultimo Empleo{" "}
              <Typography component={"span"} variant="body3" fontWeight={400}>
                (Si Aplica)
              </Typography>
            </>,
            "",
          )}
          {createRowTable("Antigüedad Laboral", formatYearsV2(0), "Numero de empresas Empleadoras", "")}
        </Grid>
      </Grid>

      <Grid
        container
        direction={"row"}
        mt={5}
        display={props.showOnlyCategoryAndVariables === false ? "none" : "flex-end"}
      >
        <Grid container direction={"column"} size={isMdDown ? 5 : 12}>
          <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
            Categorías de Análisis Lendia
          </Typography>
          <Grid container direction={"row"}>
            {createSimpleRow(
              "Ventas, Compras",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.sales_purchases) ?? 0, "")}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Flujo, Cancelación, Ratios",
              `${formatCurrency(
                Number(companyProfile.currentData?.analysis_categories?.flow_cancellation_ratios) ?? 0,
                "",
              )}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Clientes, Proveedores, P&G",
              `${formatCurrency(
                Number(companyProfile.currentData?.analysis_categories?.customers_suppliers_ps) ?? 0,
                "",
              )}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Nómina y empleados",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.payroll_employees) ?? 0, "")}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Financieros Declarados",
              `${formatCurrency(
                Number(companyProfile.currentData?.analysis_categories?.financial_declared) ?? 0,
                "",
              )}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Buró Acreditado",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.accredited_bureau) ?? 0, "")}%`,
              [4, 2, 6],
              printingMode ? (
                <></>
              ) : (
                <>
                  <IconButton
                    sx={{ p: 0, mx: seeDocAction === "PEDBA" ? 2.5 : 1 }}
                    onClick={() => handleOnSeeDocClicked("PEDBA")}
                    loading={seeDocAction === "PEDBA"}
                  >
                    {seeDocAction !== "PEDBA" && <img src={SeeIcon} />}
                  </IconButton>
                  <IconButton
                    sx={{ p: 0, mx: downloadDocAction === "PEDBA" ? 2.5 : 1 }}
                    onClick={() => handleOnDownloadDocClicked("PEDBA")}
                    loading={downloadDocAction === "PEDBA"}
                  >
                    {downloadDocAction !== "PEDBA" && <img src={DownloadIcon} />}
                  </IconButton>
                  <IconButton sx={{ p: 0, mx: 1 }} onClick={() => handleOnDownloadDocClicked("PEBAE")}>
                    <img src={ExcelIcon} />
                  </IconButton>
                </>
              ),
            )}
            {createSimpleRow(
              "Buró Persona",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.bureau_person) ?? 0, "")} %`,
              [4, 2, 6],
              printingMode ? (
                <></>
              ) : (
                <>
                  <IconButton
                    sx={{ p: 0, mx: seeDocAction === "PEDBP" ? 2.5 : 1 }}
                    onClick={() => handleOnSeeDocClicked("PEDBP")}
                    loading={seeDocAction === "PEDBP"}
                  >
                    {seeDocAction !== "PEDBP" && <img src={SeeIcon} />}
                  </IconButton>
                  <IconButton
                    sx={{ p: 0, mx: downloadDocAction === "PEDBP" ? 2.5 : 1 }}
                    onClick={() => handleOnDownloadDocClicked("PEDBP")}
                    loading={downloadDocAction === "PEDBP"}
                  >
                    {downloadDocAction !== "PEDBP" && <img src={DownloadIcon} />}
                  </IconButton>
                  <IconButton sx={{ p: 0, mx: 1 }} onClick={() => handleOnDownloadDocClicked("PEBPE")}>
                    <img src={ExcelIcon} />
                  </IconButton>
                </>
              ),
            )}
            {/* {createSimpleRow(
              "CSF & Otros",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.csf_others) ?? 0, "")}%`,
              [4, 8],
            )} */}
            <Grid size={{ xs: 4 }} p={1}>
              <Typography variant="body1" color={"#002652D9"} fontWeight={700}>
                Total:
              </Typography>
            </Grid>
            <Grid size={{ xs: 8 }} p={1}>
              <Typography color={"#002652D9"} fontWeight={600}>
                {`${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.total) ?? 0, "")} %`}
              </Typography>
            </Grid>
            <Grid size={12} height={"50%"}></Grid>
          </Grid>
          <Grid pl={5}>
            <Box width={"100%"} maxHeight={"550px"} height={"100%"}>
              <CategoriasAnalisisGrafico data={values} />
            </Box>
          </Grid>
        </Grid>
        <Grid size={"grow"} display={"flex"} justifyContent={"center"} sx={{ display: isMdDown ? "flex" : "none" }}>
          <Divider orientation="vertical" sx={{ borderColor: "rgba(163, 212, 232, 0.5)" }} />
        </Grid>
        <Grid container direction={"column"} size={isMdDown ? 5 : 12}>
          <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
            Variables de Modelo Externo
          </Typography>
          <Grid container direction={"row"}>
            {createSimpleRow(
              "Liquidez",
              companyProfile.currentData?.client_features.liquidity?.toFixed(5).toString() ?? "0",
              [8, 4],
            )}
            {createSimpleRow(
              "Variación Ingreso",
              companyProfile.currentData?.client_features.average_volatility?.toFixed(5).toString() ?? "0",
              [8, 4],
            )}
            {createSimpleRow(
              "Buró Empresa - Antigüedad primer crédito f.",
              companyProfile.currentData?.client_features.company_first_financial_credit_age_months?.toString() ?? "0",
              [8, 4],
            )}
            {createSimpleRow(
              "Buró Empresa - Consultas Buró ult. 3 meses",
              companyProfile.currentData?.client_features.credit_bureau_inquiries_last_3_months?.toString() ?? "0",
              [8, 4],
            )}
            {createSimpleRow(
              "Buró Persona - Antigüedad primera línea",
              companyProfile.currentData?.client_features.first_credit_card_age_months?.toString() ?? "0",
              [8, 4],
            )}
            {createSimpleRow(
              "Buró Persona - Porcentaje utilización líneas f.",
              `${
                companyProfile.currentData?.client_features.authorized_credit_lines_utilization_percentage_pf?.toString() ??
                0
              }%`,
              [8, 4],
            )}
            {createSimpleRow(
              "Buró Persona - Mop Max últimos 3 años",
              companyProfile.currentData?.client_features.guarantor_max_mop_last_3_years?.toString() ?? "0",
              [8, 4],
            )}
            {createSimpleRow(
              "Rango Antigüedad Actividad Meses",
              companyProfile.currentData?.client_features.main_economic_activity_seniority?.toString() ?? "0",
              [8, 4],
            )}
            {createSimpleRow(
              "Código SCIAN 3 dígitos",
              companyProfile.currentData?.client_features.scian_code_3_digits ?? "--",
              [8, 4],
            )}
            {createSimpleRow("Región", companyProfile.currentData?.client_features.region ?? "--", [8, 4])}
            {createSimpleRow(
              "Control Acc.",
              companyProfile.currentData?.client_features.majority_shareholder_participation_percentage
                ?.toFixed(2)
                .toString() ?? "N/A",
              [8, 4],
            )}
            {createSimpleRow(
              "Anticipo %.",
              companyProfile.currentData?.client_features.advance_payment?.toFixed(2).toString() ?? "N/A",
              [8, 4],
            )}
            <Grid size={{ xs: 8 }} p={1}>
              <Typography variant="body1" color={"#002652D9"} fontWeight={700}>
                Total:
              </Typography>
            </Grid>
            <Grid size={{ xs: 4 }} p={1}>
              <Typography color={"#002652D9"} fontWeight={600}>
                {/* {`${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.total) ?? 0, "")} %`} */}
                {`${formatCurrency(Number(companyProfile.currentData?.result_external_model.score) ?? 0, "")} %`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
