import { UUID } from "crypto";
import { baseApi } from "./baseApi";

import {
  AnalysisVisit,
  AnalysisVisitResponse,
  Application,
  ApplicationResponse,
  ApplicationsResponse,
  BankAccount,
  BankAccountsResponse,
  CertificateTax,
  certificateTaxStatusResponse,
  CheckControl,
  CheckControlsResponse,
  Comment,
  CommentsResponse,
  CompanyProfile,
  CompanyProfileResponse,
  Condition,
  ConditionResponse,
  ConditionsResponse,
  CreditValidation,
  CreditValidationsResponse,
  LegalIncident,
  LegalIncidentsResponse,
  ScheduleVisitResponse,
} from "@interfaces/application";

const applicationsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getApplication: build.query<Application, UUID>({
      query: (applicationId) => ({
        url: `solicitudes`,
        params: {
          application_id: applicationId,
        },
      }),
      transformResponse: (response: ApplicationResponse) => {
        return response.data.at(0);
      },
    }),
    getApplications: build.query<
      ApplicationsResponse,
      {
        q?: Array<string>;
        orderBy?: string;
        status?: Array<string>;
        companies?: Array<string>;
        sourceCodes?: Array<string>;
      }
    >({
      query: ({ q, orderBy, status, companies, sourceCodes }) => {
        const qQ = q !== undefined && q?.length !== 0 ? q.map((t) => `q=${t}`).join("&") : undefined;

        const qStatus =
          status !== undefined && status?.length !== 0 ? status.map((t) => `status=${t}`).join("&") : undefined;

        const qCompanies =
          companies !== undefined && companies?.length !== 0
            ? companies.map((t) => `company=${t}`).join("&")
            : undefined;

        const qSourceCodes =
          sourceCodes !== undefined && sourceCodes?.length !== 0
            ? sourceCodes.map((t) => `q=source_code:${t}`).join("&")
            : undefined;

        let qParams = qStatus !== undefined ? qStatus : "";
        if (qQ !== undefined) {
          qParams += qParams.trim().length !== 0 ? `&${qQ}` : qQ;
        }

        if (qCompanies !== undefined) {
          qParams += qParams.trim().length !== 0 ? `&${qCompanies}` : qCompanies;
        }

        if (qSourceCodes !== undefined) {
          qParams += qParams.trim().length !== 0 ? `&${qSourceCodes}` : qSourceCodes;
        }

        return {
          url: `solicitudes/bpm?${qParams}`,
          params: {
            order_by: orderBy,
          },
        };
      },
    }),
    putApplications: build.mutation<any, { applicationId: UUID | undefined; body: any }>({
      query: ({ applicationId, body }) => ({
        url: `solicitudes/${applicationId}`,
        method: "PUT",
        body: body,
      }),
    }),
    getScheduledVisit: build.query<ScheduleVisitResponse, UUID | undefined>({
      query: (applicationId) => ({
        url: `solicitudes/${applicationId}/agendar_visita`,
      }),
    }),
    postScheduledVisit: build.mutation<unknown, { appId: UUID | undefined; timestamp: string }>({
      query: ({ appId, timestamp }) => ({
        url: `solicitudes/${appId}/agendar_visita`,
        method: "POST",
        body: {
          date: timestamp,
          date_change: false,
          active: true,
        },
      }),
    }),
    patchScheduledVisit: build.mutation<
      unknown,
      { appId: UUID | undefined; scheduleVisitId: UUID | undefined; timestamp: string }
    >({
      query: ({ appId, scheduleVisitId, timestamp }) => ({
        url: `solicitudes/${appId}/agendar_visita/${scheduleVisitId}`,
        method: "PATCH",
        body: {
          date: timestamp,
          date_change: true,
          active: true,
        },
      }),
    }),
    postLostInterest: build.mutation<any, { applicationId: UUID; razon_uninterested_id: UUID }>({
      query: ({ applicationId, razon_uninterested_id }) => ({
        url: `solicitudes/${applicationId}/perdida_interes`,
        method: "POST",
        body: {
          uninterest_reasons: [
            {
              razon_uninterested_id: razon_uninterested_id,
            },
          ],
        },
      }),
    }),
    postReasonRejectApp: build.mutation<
      any,
      {
        applicationId: UUID | undefined;
        payload: any;
      }
    >({
      query: ({ applicationId, payload }) => ({
        url: `solicitudes/${applicationId}/razones_rechazo`,
        method: "POST",
        body: { rejection_reasons: payload },
      }),
    }),
    getCommentsByApp: build.query<Array<Comment>, UUID>({
      query: (applicationId) => ({
        url: `solicitudes/${applicationId}/comentarios`,
      }),
      transformResponse: (response: CommentsResponse) => {
        return response.data.data;
      },
    }),
    postCommentByApp: build.mutation<any, { applicationId: UUID; payload: unknown }>({
      query: ({ applicationId, payload }) => ({
        url: `solicitudes/${applicationId}/comentarios`,
        method: "POST",
        body: payload,
      }),
    }),
    deleteCommentByApp: build.mutation<any, { applicationId: UUID; commentId: UUID }>({
      query: ({ applicationId, commentId }) => ({
        url: `solicitudes/${applicationId}/comentarios/${commentId}`,
        method: "DELETE",
      }),
    }),
    patchCommentByApp: build.mutation<any, { applicationId: UUID; commentId: UUID; payload: unknown }>({
      query: ({ applicationId, commentId, payload }) => ({
        url: `solicitudes/${applicationId}/comentarios/${commentId}`,
        method: "PATCH",
        body: payload,
      }),
    }),
    getCommentsByModule: build.query<Array<Comment>, { applicationId: UUID; moduleId: UUID }>({
      query: ({ applicationId, moduleId }) => ({
        url: `solicitudes/${applicationId}/comentarios`,
        params: {
          q: `module_id:${moduleId}`,
        },
      }),
      transformResponse: (response: CommentsResponse) => {
        return response.data.data;
      },
    }),
    getCompanyProfile: build.query<CompanyProfile, { rfc: string; loanApplicationId: UUID }>({
      query: ({ rfc, loanApplicationId }) => ({
        url: `sat_core/company_profile/${rfc}`,
        params: {
          loan_application_id: loanApplicationId,
        },
      }),
      transformResponse: (response: CompanyProfileResponse) => {
        return response.data;
      },
    }),
    getCSF: build.query<Array<CertificateTax>, string>({
      query: (params) => ({
        url: `sat_core/certificate_tax_status?${params}`,
      }),
      transformResponse: (response: certificateTaxStatusResponse) => {
        return response.data.data;
      },
    }),
    postStatusApplication: build.mutation<
      any,
      { applicationId: UUID; statusCode: string; userName: string; disableSequenceCheck?: boolean }
    >({
      query: ({ applicationId, statusCode, userName, disableSequenceCheck = undefined }) => ({
        url: `solicitudes/${applicationId}/progreso`,
        method: "POST",
        body: {
          user_name: `BPM - ${userName}`,
          status_code: statusCode,
          disable_sequence_check: disableSequenceCheck,
        },
      }),
    }),
    getBankAccountsApplication: build.query<Array<BankAccount>, UUID>({
      query: (applicationId) => ({
        url: `solicitudes/${applicationId}/cuentas_bancarias`,
      }),
      transformResponse: (response: BankAccountsResponse) => {
        return response.data;
      },
    }),
    patchBankAccountsApplication: build.mutation<any, { applicationId: UUID; bankAccountId: UUID; payload: any }>({
      query: ({ applicationId, bankAccountId, payload }) => ({
        url: `solicitudes/${applicationId}/cuentas_bancarias/${bankAccountId}`,
        method: "PATCH",
        body: payload,
      }),
    }),
    putCondition: build.mutation<any, { applicationId: UUID; conditionId: UUID; body: any }>({
      query: ({ applicationId, conditionId, body }) => ({
        url: `solicitudes/${applicationId}/condiciones/${conditionId}`,
        method: "PUT",
        body: body,
      }),
    }),
    postCondition: build.mutation<any, { applicationId: UUID; body: any }>({
      query: ({ applicationId, body }) => ({
        url: `solicitudes/${applicationId}/condiciones`,
        method: "POST",
        body: body,
      }),
    }),
    getCondition: build.query<Condition, { applicationId: UUID; conditionId: UUID }>({
      query: ({ applicationId, conditionId }) => ({
        url: `solicitudes/${applicationId}/condiciones/${conditionId}`,
      }),
      transformResponse: (response: ConditionResponse) => {
        return response.data;
      },
    }),
    getConditions: build.query<Array<Condition>, { applicationId: UUID; params?: string }>({
      query: ({ applicationId, params }) => ({
        url: `solicitudes/${applicationId}/condiciones?${params}`,
      }),
      transformResponse: (response: ConditionsResponse) => {
        return response.data.data;
      },
    }),
    getCreditValidations: build.query<Array<CreditValidation>, UUID>({
      query: (applicationId) => ({
        url: `solicitudes/${applicationId}/validacion_crediticia`,
      }),
      transformResponse: (response: CreditValidationsResponse) => {
        return response.data.data;
      },
    }),
    patchCreditValidation: build.mutation<any, { applicationId: UUID; creditValidationId: UUID; body: any }>({
      query: ({ applicationId, creditValidationId, body }) => ({
        url: `solicitudes/${applicationId}/validacion_crediticia/${creditValidationId}`,
        method: "PATCH",
        body: body,
      }),
    }),
    getLegalIncidents: build.query<Array<LegalIncident>, UUID>({
      query: (applicationId) => ({
        url: `solicitudes/${applicationId}/incidencias_legales`,
      }),
      transformResponse: (response: LegalIncidentsResponse) => {
        return response.data.data;
      },
    }),
    postLegalIncident: build.mutation<any, { applicationId: UUID; body: any }>({
      query: ({ applicationId, body }) => ({
        url: `solicitudes/${applicationId}/incidencias_legales`,
        method: "POST",
        body: body,
      }),
    }),
    patchLegalIncident: build.mutation<any, { applicationId: UUID; legalIncidentId: UUID; body: any }>({
      query: ({ applicationId, legalIncidentId, body }) => ({
        url: `solicitudes/${applicationId}/incidencias_legales/${legalIncidentId}`,
        method: "PATCH",
        body: body,
      }),
    }),
    getAnalysisVisit: build.query<Array<AnalysisVisit>, UUID>({
      query: (applicationId) => ({
        url: `solicitudes/${applicationId}/analisis_visita`,
      }),
      transformResponse: (response: AnalysisVisitResponse) => {
        return response.data.data;
      },
    }),
    patchAnalysisVisit: build.mutation<any, { applicationId: UUID; analysisVisitId: UUID; body: any }>({
      query: ({ applicationId, analysisVisitId, body }) => ({
        url: `solicitudes/${applicationId}/analisis_visita/${analysisVisitId}`,
        method: "PATCH",
        body: body,
      }),
    }),
    getCheckControls: build.query<Array<CheckControl>, UUID>({
      query: (applicationId) => ({
        url: `solicitudes/${applicationId}/check_control`,
      }),
      transformResponse: (response: CheckControlsResponse) => {
        return response.data.data;
      },
    }),
    patchCheckControl: build.mutation<any, { applicationId: UUID; checkControlId: UUID; body: any }>({
      query: ({ applicationId, checkControlId, body }) => ({
        url: `solicitudes/${applicationId}/check_control/${checkControlId}`,
        method: "PATCH",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useLazyGetApplicationsQuery,
  useLazyGetApplicationQuery,
  usePutApplicationsMutation,
  useGetScheduledVisitQuery,
  usePostScheduledVisitMutation,
  usePatchScheduledVisitMutation,
  usePostLostInterestMutation,
  usePostReasonRejectAppMutation,
  useGetCommentsByAppQuery,
  useLazyGetCommentsByAppQuery,
  usePostCommentByAppMutation,
  useDeleteCommentByAppMutation,
  usePatchCommentByAppMutation,
  useLazyGetCommentsByModuleQuery,
  useGetCompanyProfileQuery,
  useLazyGetCompanyProfileQuery,
  usePostStatusApplicationMutation,
  useGetBankAccountsApplicationQuery,
  usePutConditionMutation,
  usePostConditionMutation,
  useGetConditionQuery,
  useLazyGetConditionQuery,
  useLazyGetConditionsQuery,
  usePatchBankAccountsApplicationMutation,
  useLazyGetBankAccountsApplicationQuery,
  useLazyGetCreditValidationsQuery,
  usePatchCreditValidationMutation,
  useGetLegalIncidentsQuery,
  useLazyGetLegalIncidentsQuery,
  usePostLegalIncidentMutation,
  usePatchLegalIncidentMutation,
  useLazyGetAnalysisVisitQuery,
  usePatchAnalysisVisitMutation,
  useLazyGetCheckControlsQuery,
  usePatchCheckControlMutation,
  useLazyGetCSFQuery,
} = applicationsApi;
