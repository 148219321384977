import { Typography } from "@mui/material";

import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import { CategoryScale, ChartData, ChartOptions } from "chart.js";

import { formatCurrency } from "@helpers/formats";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);
Chart.register(annotationPlugin);

interface GraficoBarrasGenericoProps {
  title: string;
  subtitle?: string;
  datasets: any;
  labels: Array<string>;
  stacked?: boolean;
  showDataLabels?: boolean;
  showTooltips?: boolean;
  isLoading?: boolean;
  showLabelsX?: boolean;
  showLabelsY?: boolean;
  showGridY?: boolean;
}

export const GraficoBarrasGenerico = (props: GraficoBarrasGenericoProps) => {
  const data: ChartData<"bar"> = {
    labels: props.labels,
    datasets: props.datasets,
  };

  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      tooltip: {
        enabled: props.showTooltips ?? false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: props.showDataLabels ?? false,
        formatter: (value, ctx) => {
          return formatCurrency(value, "$", 2);
        },
      },
      annotation: {
        annotations: {
          // label1: {
          //   type: "label",
          //   xValue: 0,
          //   yValue: 0.5,
          //   drawTime: "afterDatasetsDraw",
          //   display: (ctx: any) => {
          //     const datasets = ctx.chart.data.datasets;
          //     const result = datasets
          //       .map((dataset: any) => {
          //         return dataset.data.length > 0;
          //       })
          //       .some((value: boolean) => value === true);
          //     return !result;
          //   },
          //   content: ["Sin datos para mostrar"],
          //   font: {
          //     size: 18,
          //   },
          // },
          label1: {
            type: "line",
            // scaleID: "y",
            value: 0.5,
            borderWidth: 0,
            drawTime: "afterDatasetsDraw",
            display: (ctx: any) => {
              if (props.isLoading) {
                return false;
              }
              const datasets = ctx.chart.data.datasets;
              const result = datasets
                .map((dataset: any) => {
                  return dataset.data.length > 0;
                })
                .some((value: boolean) => value === true);
              return !result;
            },
            label: {
              content: ["Sin datos para mostrar"],
              display: true,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              color: "#000",
              font: {
                size: 18,
              },
            },
          },
          label2: {
            type: "line",
            value: 0.5,
            borderWidth: 0,
            drawTime: "afterDatasetsDraw",
            display: props.isLoading,
            label: {
              content: ["Cargando información..."],
              display: true,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              color: "#000",
              font: {
                size: 18,
              },
            },
          },
        },
      },
    },
    scales: {
      x: {
        stacked: props.stacked ?? false,
        display: props.showLabelsX,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: props.stacked ?? false,
        beginAtZero: true,
        grid: {
          display: props.showGridY,
        },
        // max: Math.max(...(props.datasets.at(0)?.data ?? [])) + Math.max(...(props.datasets.at(0)?.data ?? [])) / 4,
        ticks: {
          color: "#b2a7ab",
          callback: (value, _index, _ticks) => {
            // if (props.horizontalBars) {
            //   return props.labels[_index];
            // }
            return formatCurrency(
              Number(value) < 0 ? Number(value) * -1 : Number(value),
              Number(value) < 0 ? "-$" : "$",
              0,
            );
          },
          font: {
            size: 12,
            weight: "bold",
          },
        },
      },
    },
  };
  return (
    <>
      <Typography variant="h3" fontWeight={700} color="#002652" mb={2}>
        {props.title}
        <Typography component={"span"} variant="h5" fontWeight={400} color="#002652">
          {" "}
          {props.subtitle}
        </Typography>
      </Typography>
      <div className="chart-container" style={{ position: "relative", height: "25vh" }}>
        <Bar data={data} options={options} />
      </div>
    </>
  );
};
